<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="enterprises-page">
    <b-card class="" title="Manage Enterprises">
      <b-card-text>This page shows a list of all available enterprises.</b-card-text>
    </b-card>

    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="4" lg="3">
            <b-btn :to="{ name: 'enterprise-create'}"
                   variant="success"
                   class="mr-2 float-left">
              Create Enterprise
            </b-btn>
          </b-col>
          <b-col cols="12" md="8" lg="9" class="mt-1 mt-md-0">
            <b-input-group>
              <b-form-input
                @keydown.enter="searchEnterprises()"
                v-model="searchVal"
                class="d-inline-block"
                placeholder="Search for an enterprise..."
              />
              <b-input-group-append>
                <b-button @click="searchEnterprises()">Search</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </div>
      <b-table
        class="data-table"
        hover
        responsive
        :items="enterprises"
        :fields="headers"
        :current-page="currentPage"
        :per-page="0"
      >
        <template #cell(created_at)="data">
          {{data.item.created_at | formatDateTime}}
        </template>
        <template #cell(actions)="data">
          <b-btn v-if="$can('Manage', 'All')"
                 :to="{ name: 'enterprise-edit', params: { uid: data.item.uid }}"
                 variant="primary"
                 class="ml-2">Update</b-btn>
        </template>
        <template #cell(display_name)="data">
          <router-link :to="{ name: 'enterprise-view', params: { uid: data.item.uid, enterprise: data.item } }" class="font-weight-bold d-block text-nowrap">
            {{data.item.display_name}}
          </router-link>
        </template>
      </b-table>
      <b-row>
        <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
          <b-pagination @change="getMoreEnterprises" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
        </b-col>
        <b-col class="ml-2 mt-1" v-if="total_count === 0">
          <p >No results</p>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<!--eslint-enable-->

<script>
  import EnterpriseService from '../../services/EnterpriseService';

  export default {
    name: 'Enterprises',
    components: {
    },
    props: {},
    data() {
      return {
        selectedEnterprise: null,
        headers: [
          {
            key: 'display_name',
            label: 'Display Name',
          },
          {
            key: 'name',
            label: 'Name',
          },
          {
            key: 'created_at',
            label: 'Created At',
          },
          {
            key: 'uid',
            label: 'UID',
          },
          {
            key: 'actions',
            label: '',
            class: 'text-right',
          },
        ],
        enterprises: [],
        currentPage: 1,
        searchVal: '',
        filters: {
          page: 0,
          page_size: 25,
          search: '',
        },
        total_count: 0,
      };
    },
    watch: {
      filters: {
        deep: true,
        handler: function(newVal) {
          let queryString = `?page=${newVal.page+1}`;
          if(newVal.search !== '') {
            queryString += `&search=${newVal.search}`
          }
          history.replaceState({}, null, `${this.$route.path}${queryString}`)
        }
      }
    },
    mounted() {
      if(this.$route.query.page) {
        this.currentPage = parseInt(this.$route.query.page, 10)
        this.filters.page = this.currentPage - 1
      }
      if(this.$route.query.search) {
        this.filters.search = this.$route.query.search
        this.searchVal = this.$route.query.search
      }
      this.getEnterprises();
    },
    methods: {
      refreshEnterprises() {
        this.closeModals();
        this.getEnterprises();
      },
      getMoreEnterprises(val) {
        this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
        this.getEnterprises();
      },
      searchEnterprises() {
        this.filters.search = this.searchVal
        this.currentPage = 1;
        this.filters.page = 0;
        this.getEnterprises();
      },
      getEnterprises() {
        EnterpriseService.getEnterprises(this.filters).then(res => {
          this.enterprises = res.data.data;
          this.filters.page = res.data.meta.page;
          this.filters.page_size = res.data.meta.page_size;
          this.total_count = res.data.meta.total_count;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get enterprises, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      closeModals() {
        this.$bvModal.hide('modal-update-enterprise');
      },
      setSelectedEnterprise(enterprise) {
        this.selectedEnterprise = enterprise;
      },
      showEnterpriseModal(enterprise, modalName) {
        this.setSelectedEnterprise(enterprise);
        this.$bvModal.show(modalName);
      },
    },
  };
</script>
